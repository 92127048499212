import * as React from 'react'
import Layout from '../components/layout'
import Search from "../components/POC1";
import {graphql} from "gatsby";

const BlogPage = ({location, data}) => {
	return (
		<Layout pageTitle="Les recettes ChefClub">
			<Search
				pathname={data && data.sitePage && data.sitePage.context && data.sitePage.context.slug && data.sitePage.context.slug !== "" ? data.sitePage.context.slug : (location ? location.pathname : "")}
				hash={location ? location.hash : ""}
				search={location ? location.search : null}
			/>
		</Layout>
	)
}

export default BlogPage
export const query = graphql`
  query($slug: String!) {
	  sitePage(context: {slug: {eq: $slug}}) {
	    id
	    component
	    context {
	      slug
	    }
	  }
	}
`